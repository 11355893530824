<!-- 新增事件分类 -->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="40%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <ax-form ref="formBox" :formBuilder="formBuilder">
        <div
          slot="typeName"
          slot-scope="{ record }"
          v-decorator="[
            'typeName',
            { rules: [{ required: true, message: '请输入分组类别' }] },
          ]"
        >
          <div
            style="display: flex; align-items: center; margin: 5px 0"
            v-for="(item, index) in formItems"
            :key="index"
          >
            <a-input v-model="item.typeName" placeholder=""></a-input>
            <a-icon
              style="font-size: 0.1rem"
              type="close"
              v-if="formItems.length&&index > 0"
              @click="deleteItem(index)"
            />
          </div>
          <ax-button @click="addItem" style="margin-top: 0.5%"
            >添加选项</ax-button
          >
        </div>
      </ax-form>
      <div style="display: flex; justify-content: flex-end">
        <a-button type="primary" @click="onSubmit" :loading="saveType">
          保存
        </a-button>
        <a-button style="margin-left: 20px" @click="closeModal">
          取消
        </a-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "./api";
const formList = [
  {
    label: "事件名称",
    type: "",
    model: "eventName",
    options: { placeholder: "请输入", showTime: true, maxLength: 50 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
        message: "活动名称不能为空",
      },
    ],
  },
  {
    label: "事件分类",
    type: "",
    model: "typeName",
    col: { span: 24 },
    options: { minRows: 4, maxRows: 4 },
    rules: [
      {
        required: true,
        // pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/,
        message: "事件分类不能为空",
      },
    ],
  },
];

export default {
  components: {},
  data() {
    return {
      api,
      visible: false,
      saveType: false,
      typeName: "",
      title: "",
      formItems: [{ typeName: "" }],
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.title = record.title;
      if (record.id) {
      } else {
        this.isEdit = true;
      }
    },
    // 关闭弹窗
    closeModal() {
      this.$refs.formBox.resetFields();
      this.formItems = [{ typeName: "" }];
      this.typeName = "";
      this.visible = false;
      this.$emit("refsh");
    },
    addItem() {
      this.formItems.push({ typeName: "" });
      this.typeName = this.formItems[0].typeName;
    },
    deleteItem(index) {
      this.formItems.splice(index, 1);
      console.log(this.formItems, "125");
      if (this.formItems.length) {
        this.typeName = this.formItems[0].typeName;
      } else {
        this.typeName = "";
      }
    },
    getTypeName() {
      this.formItems = this.formItems.map(item => item.typeName)
      this.typeName = this.formItems[0];
      console.log(this.formItems, this.typeName);
    },
    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        this.getTypeName();
        value.typeName = this.formItems.join(',');
        console.log(value, 161);
        // this.saveType = true; // 验证通过后再设置
        // try {
        //   const res = await api.add(value);
        //   // console.log(res);
        //   if (res.status === 200) {
        //     this.$message.success("新增成功");
        //     this.saveType = false;
        //     // 提交成功后关闭弹窗
        //     this.$emit("closeDialog");
        //     this.refreshTable();
        //   } else {
        //     this.$message.error("新增失败");
        //     this.saveType = true; // 验证通过后再设置
        //   }
        // } catch (err) {
        //   console.log(error);
        //   this.$message.error("新增失败");
        //   this.saveType = false;
        // }
      });
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>
    