<!-- 事件分类表格页面  -->
<template>
  <div class="">
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :showToolBar="true"
      :toolActions="toolActions"
      :rowSelection="rowSelection"
      @pop-confirm="popConfirm"
      @add="add"
      @bulkDeletion="dels"
      @action-column-click="actionColumnClick"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!-- 事件分类 用顿号分开  -->
      <div slot="deleteFlag" slot-scope="{ record }">
        {{ record.nativePlace.split("/").join("、")
        }}{{ record.nativePlace.split("/").join("、") }}
      </div>
    </ax-table>
    <!-- 详情弹窗 -->
    <addClassIfication ref="addClassIfication"></addClassIfication>
    <!-- <watch ref="watch"></watch> -->
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const searchForm = [
  {
    label: "事件描述",
    type: "input",
    model: "searchText",
    options: { placeholder: "请输入..." },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "上报来源",
    type: "select",
    model: "eventCategory",
    options: { placeholder: "请选择", mode: "multiple" }, //多选
    formItem: {},
    col: { span: 5 },
  },
  {
    label: "事件类别",
    type: "select",
    model: "eventSource",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 5 },
  },
  {
    label: "当前状态",
    type: "select",
    model: "eventSource1",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 4 },
  },
  {
    label: "紧急程度",
    type: "select",
    model: "eventSource2",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 4 },
  },
];

import api from "./api";
import addClassIfication from "./addClassIfication.vue";
export default {
  components: { addClassIfication },
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      visible: false,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "分组名称",
            dataIndex: "nativePlace",
            ellipsis: true,
            align: "left",
            width: 80,
          },

          {
            title: "事件分类",
            dataIndex: "deleteFlag",
            ellipsis: false,
            align: "left",
            width: 80,
          },
          {
            title: "更新日期",
            dataIndex: "updateTime",
            ellipsis: true,
            width: 100,
          },
        ],
        false,
        {
          // fixed: "right",
          width: 80,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#556BFF",
                  link: true,
                  popDisabled: true, //是否要弹窗,
                  // title: "确定恢复团员信息?",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //是否不要弹窗,
                  title: "确定删除该条事件信息?",
                };
              },
            },
          ],
        }
      ),
      rowSelection: { onChange: this.onSelectChange },
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      toolActions: [
        { name: "add", text: "新增", type: "#556BFF" },
        { name: "bulkDeletion", text: "批量删除", type: "#f95a5a" },
      ],
      dataSourceApis: api.list,
      dataSourceParams: { type: "0" },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "edit":
          this.$refs.addClassIfication.openModal({ record, title: "编辑事件分类" });
          break;
        // 删除
        case "del":
          break;
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.dels(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.tableBox.getDataSource();
    },
    // 多选
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    // 新增事件分类
    add() {
      this.$refs.addClassIfication.openModal({ title: "新增事件分类" });
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    //批量删除
    async dels() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async () => {
            // 使用箭头函数确保this指向组件实例
            const res = await api.deleteBatch(this.selectedRowKeys);
            if (res.status === 200) {
              this.$message.success("删除成功");
              // 刷新列表数据
              this.$refs.roleTable.getDataSource();
            } else {
              this.$message.error("删除失败");
            }
          },
        });
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    // 获取字典
    async getDictionsary() {
      // 事件类别
      await api.dictData({ dicKind: "event_category" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "eventCategory",
          {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              mode: "multiple",
            },
          }
        );
      });
      // 事件来源
      await api.dictData({ dicKind: "event_source" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "eventSource",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.getDictionsary();
    // this.getRegionInfo();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>